<template>
  <Modal
    :is-not-close="isNotClose"
    :ui="{ root: { size: 'max-w-140', addition: 'max-lg:p-4.5 max-lg:rounded-b-0' }, wrapper: { addition: 'max-lg:justify-center max-lg:items-end max-lg:pb-0' } }"
    @close="emits('closeModal')"
  >
    
<FormKitLazyProvider config-file="true" :default-config="false">
<div class="flex flex-col lg:flex-row gap-y-5 gap-x-11">
      <div>
        <p class="text-tekstovyy text-6 lg:text-7.5 font-700">Стать партнёром</p>

        <FormKit
          type="form"
          form-class="flex flex-col gap-5 lg:gap-7 mt-4 lg:mt-7"
          :actions="false"
          :incomplete-message="false"
          :config="{ messagesClass: 'absolute top-full left-0 w-full', outerClass: 'relative', inputClass: '$remove:text-tekstovyy text-firmennyy $remove:placeholder:text-#AAB8C1 placeholder-firmennyy/50 group-data-[invalid]:text-#ED6A41 group-data-[invalid]:placeholder-#ED6A41/50', innerClass: 'bg-plashka-green $remove:border-border border-plashka-green focus-within:border-firmennyy/20 group-data-[invalid]:bg-#ED6A41/10 group-data-[invalid]:border-#ED6A41/50' }"
          @submit="onSend"
        >
          <FormKit
            name="org_title"
            type="text"
            placeholder="Название компании"
            validation="required:trim"
            validation-label="Название компании"
          />
          <FormKit
            name="name"
            type="text"
            placeholder="Ваше имя"
            validation="required:trim"
            validation-label="Ваше имя"
          />
          <FormKit
            v-maska
            name="phone"
            type="tel"
            placeholder="Телефон"
            validation="required:trim | length:17,17"
            validation-label="Телефон"
            data-maska="+7(###) ### ## ##"
          />
          <Button type="submit">Отправить заявку</Button>
        </FormKit>
      </div>
      <div class="flex lg:flex-col items-center shrink-0 gap-x-3.5 gap-y-4 max-lg:order-first lg:max-w-35">
        <img
          width="140"
          height="140"
          class="size-22.5 lg:size-35"
          src="/imgs/authentication/partner.png"
          alt=""
        >
        <p class="text-firmennyy text-3 font-500 leading-1.3 lg:text-center">Привлекайте новых посетителей и расширяйте зону продажи</p>
      </div>
    </div>
    <p class="text-seryy text-3 leading-1.3 mt-3 lg:mt-4.5 max-lg:text-center max-w-90">
      Я полностью согласен с условиями <NuxtLink
        class="text-firmennyy underline"
        to="/offer"
      >
        Договора оказания услуг Sakh Food
      </NuxtLink>,
      <NuxtLink
        class="text-firmennyy underline"
        to="/checking-partners"
      >
        Порядком проверки Партнеров при подключении к Сервису
      </NuxtLink> и обязуюсь соблюдать <NuxtLink
        class="text-firmennyy underline"
        to="/standarts-partners"
      >
        Правила и стандарты Sakh Food
      </NuxtLink>
    </p>
</FormKitLazyProvider>

  </Modal>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { vMaska } from 'maska';
import type { PartnersBodyType } from '~/composables/partners/type/partnersType';
import { usePartners } from '~/composables/partners/usePartners';

const props = defineProps({
  isNotClose: { type: Boolean, default: true }
});

const emits = defineEmits(['closeModal']);
const { sendPartner } = usePartners();
const { $toast } = useNuxtApp();

async function onSend (data:PartnersBodyType) {
  const res = await sendPartner({ ...data, phone: data.phone.replace(/\D/g, '') })
	  .catch((err) => {
      $toast.error(err.data.message);
      return false;
	  });

  if (res) {
    $toast.success('Ваша заявка принята');
    emits('closeModal');
  }
}
</script>
